import React from 'react'
import Button from '@material-ui/core/Button'
import { TextField, CircularProgress } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { Link as MuiLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import Notification from '../../../components/notification'
import validationSchema from '../validation'
import PropTypes from 'prop-types'
import { post } from '../../../services/api'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import Img from '../../../static/logo/logo-fidepol-512.png'

const Format = (date) => {
    let result = null
    try{ 
        result = format(
            date,
            'yyyy/MM/dd',
            { locale: es })
    }catch(err) {
        console.log(err)
    }
    return result
}

const SignUpFormEmail = (props) => {
    const { classes } = props
    const navigate = useHistory()
    const [externalError, setExternalError] = React.useState(null)
    const [selectedDate, setSelectedDate] = React.useState(null)
    const handleDateChange = (date) => {
        setSelectedDate(date)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.paper}>
                <img src={Img} alt="Logo" style={{ width: 250 }} />
                <Formik
                    initialValues={{ email: "", numEmp: "" }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setExternalError(null)
                        try {
                            const response = await post("/api/register", {
                                ...values,
                                date: Format(selectedDate)
                            })
                            setExternalError(response.data.Message)
                            setTimeout(() => {
                                setExternalError(null)
                                setSubmitting(false)
                                navigate.push("/sign-in")                                
                            },2000)

                        }catch(err) {
                            setExternalError(err.response.data.Message)
                            setSubmitting(false)
                        }
                        

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    autoFocus
                                    label="Número de empleado"
                                    name="numEmp"
                                    id="numEmp"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.numEmp}
                                    error={touched.numEmp && errors.numEmp}
                                    helperText={errors.numEmp}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date"
                                    name="date"
                                    label="Fecha de Ingreso"
                                    format="dd/MM/yyyy"
                                    value={selectedDate}
                                    fullWidth
                                    required
                                    onChange={handleDateChange}
                                    error={!selectedDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Correo electrónico"
                                    autoComplete="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={touched.email && errors.email}
                                    helperText={errors.email}
                                />
                                {!isSubmitting &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        REGISTRAR
                                </Button>
                                }
                                <Grid container>
                                    <Grid item xs={12} className={classes.spinner}>
                                        {isSubmitting && <CircularProgress />}
                                    </Grid>
                                </Grid>
                                {externalError &&
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Notification isOpen={externalError ? true : false} message={externalError} />
                                        </Grid>
                                    </Grid>
                                }
                                {!isSubmitting &&
                                    <Grid item xs>
                                        <Link component={MuiLink} to="/sign-in" variant="body2">Regresar a Ingresar</Link>
                                    </Grid>
                                }
                            </form>
                        )}
                </Formik>
            </div>
        </MuiPickersUtilsProvider>
    )
}

SignUpFormEmail.propTypes = {
    classes: PropTypes.object,
    login: PropTypes.func
}

export default SignUpFormEmail;