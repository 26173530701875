import React from 'react'
import { Grid, Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 32,
        height: 20,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);



const Index = (props) => {
    const { leftText, rightText, onChange, checkedA } = props
    const [state, setState] = React.useState({
        checkedA: checkedA
    })

    const handleChange = (event) => {
        if(onChange) {
            setState({ ...state, [event.target.name]: event.target.checked })
            onChange({ ...state, [event.target.name]: event.target.checked })
        }
    }

    React.useEffect(() => {
        setState( prev => ({
            checkedA: checkedA
        }))
    }, [checkedA]);
    

    return (
        <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>{leftText}</Grid>
            <Grid item>
                <CustomSwitch checked={state.checkedA} onChange={handleChange} name="checkedA" />
            </Grid>
            <Grid item>{rightText}</Grid>
        </Grid>
    )
}

Index.propTypes = {
    leftText: PropTypes.string.isRequired,
    rightText: PropTypes.string.isRequired,
    checkedA: PropTypes.bool
}

export default Index
